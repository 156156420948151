import { Close, Download } from "@mui/icons-material";
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Pagination,
    Select,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Api } from "../../../apis";
import Relevencies from "./Chronology/Relevencies";
import { Context } from "../../../store/store";
import Bounce from "../../Loader/Bounce";
import moment from "moment";
import ExcelJS from "exceljs";
import SaveAs from "file-saver";
import Filters from "./Filters";
import Collapse from "../../../Icons/Collapse";
import Expand from "../../../Icons/Expand";
import Chronology from "./Chronology/Chronology";

const Tables = () => {
    const navigate = useNavigate();
    const tableRef = useRef<any>();
    const { contextStore, setContext } = useContext<any>(Context);
    const [tabSelect, setTabSelect] = useState<"chronology" | "clauses_result" | "de_period_result">("chronology");
    const [loader, setLoader] = useState<boolean>(false);
    const [tableData, setTableData] = useState<any>([]);
    const [expand, setExpand] = useState<boolean>(false);
    const [filterStatus, setFilterStatus] = useState<boolean>(false);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);

    const adjustColumnWidth = (ws: any) => {
        ws.columns.forEach((column: any) => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, (cell: any) => {
                const cellLength = cell.value ? cell.value.toString().length : 10;
                if (cellLength > maxLength) {
                    maxLength = cellLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength + 2; // Adjust for padding
        });
    };
    const handleDownload = () => {
        if (tableData?.length > 0) {
            const workbook = new ExcelJS.Workbook();
            workbook.creator = "Claims-AI";
            workbook.created = new Date();
            let worksheet = workbook.addWorksheet(`${tabSelect} Report`);
            let headers: string[] = [];
            let lastHeadRow = 1;
            if (tabSelect === "chronology") {
                headers = [
                    "Sn.",
                    "Delay Event",
                    "Delay Event",
                    "Party",
                    "Department",
                    "Previous Reference Letter",
                    "Letter Reference No.",
                    "Date",
                    "Response Letter(s)",
                    "Response Letter's Date",
                    "Response Time",
                    "Referred In Claims",
                    "Missing in Claims",
                    "Letter Type",
                    "Gist",
                    "Contradictions wrt the (Contract / FIDIC (Yellow / Pink Book)",
                    // "Contradictions wrt the (Contract / FIDIC (Yellow / Pink Book)",
                    // "Contradictions wrt the (Contract / FIDIC (Yellow / Pink Book)",
                    "Contradictions wrt the Party's Letter",
                    "Contradictions wrt the Party's Letter",
                    // "Contradictions wrt the Party's Letter",
                ];
                worksheet.columns = headers?.map((key) => ({ header: key, key, width: 10 }));
                worksheet?.addRow([
                    "",
                    "DE No.",
                    "Sub Delay Event",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    // "Contrasts",
                    // "Clauses/Sub-Clauses in letter",
                    "Relevance",
                    "Reference Letter",
                    "Letter Contrasts (Party Communication)",
                    // "Clauses/Sub-Clauses in letter",
                ]);
                [
                    ["B", "C"],
                    ["P", "P"],
                    ["Q", "R"],
                ]?.forEach((key) => worksheet.mergeCells(`${key[0]}1:${key[1]}1`));
                ["A", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"]?.forEach((key) => worksheet.mergeCells(`${key}1:${key}2`));
                ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R"]?.forEach((row: string) => {
                    worksheet.getCell(`${row}${lastHeadRow}`).alignment = { wrapText: true };
                    worksheet.getCell(`${row}${lastHeadRow}`).fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "c9d9ff" },
                    };
                    worksheet.getCell(`${row}${lastHeadRow}`).font = {
                        color: { argb: "00000000" },
                        bold: true,
                    };
                    worksheet.getCell(`${row}${lastHeadRow}`).border = {
                        top: { style: "thin", color: { argb: "8F8F8F8F" } },
                        left: { style: "thin", color: { argb: "8F8F8F8F" } },
                        bottom: { style: "thin", color: { argb: "8F8F8F8F" } },
                        right: { style: "thin", color: { argb: "8F8F8F8F" } },
                    };
                    worksheet.getCell(`${row}${lastHeadRow + 1}`).fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "c9d9ff" },
                    };
                    worksheet.getCell(`${row}${lastHeadRow + 1}`).font = {
                        color: { argb: "00000000" },
                        bold: true,
                    };
                    worksheet.getCell(`${row}${lastHeadRow + 1}`).border = {
                        top: { style: "thin", color: { argb: "8F8F8F8F" } },
                        left: { style: "thin", color: { argb: "8F8F8F8F" } },
                        bottom: { style: "thin", color: { argb: "8F8F8F8F" } },
                        right: { style: "thin", color: { argb: "8F8F8F8F" } },
                    };
                    worksheet.getColumn(`${row}`).width = 35;
                });
                ["P1", "P2", "S1", "S2", "T2", "U2"]?.forEach((row: string) => {
                    worksheet.getCell(row).font = {
                        color: { argb: "FFFF0000" },
                    };
                });
                tableData?.forEach((item: any, index: number) => {
                    worksheet.addRow([
                        `${index + 1}`,
                        ...["Delay Event", "Sub Delay Event", "Party", "Department", "Previous Reference Letter", "Letter Reference No."]?.map(
                            (key) => item[key]?.toString()
                        ),
                        moment(item["Date"]?.toString())?.isValid()
                            ? moment(item["Date"]?.toString())?.format("YYYY-MM-DD")
                            : item["Date"]?.toString(),
                        ...["Response Letter", "Response Letter's Date", "Response Time"]?.map((key) => item[key]?.toString()),
                        item["Referred"]?.toString(),
                        ...["Missing in Claims"]?.map((key) => item[key]?.toString()),
                        ...["Letter Type"]?.map((key) => item[key]?.toString()),
                        item["Gist"]?.toString(),
                        // ...["Contract-Contrasts", "Clauses/Sub-Clauses in letter", "Relevancies"]?.map((key) => item[key]?.toString()),
                        ...["Relevancies"]?.map((key) => (item[key] || "NA")?.toString()),
                        ...["Contrasting Letter Number", "Letter Contrasts"]?.map((key) => (item[key] || "NA")?.toString()),
                    ]);
                    lastHeadRow += 1;
                });
                // adjustColumnWidth(worksheet);
            }
            if (tabSelect === "de_period_result") {
                headers = [
                    "Delay Event",
                    "Delay Start Date",
                    "Delay End Date",
                    "Submission Status under 28 Days",
                    "Submission Status (Otherwise)",
                    "Other Notices/Supporting Particulars",
                    "Under Clauses",
                ];
                worksheet.columns = headers?.map((key) => ({ header: key, key, width: 10 }));
                ["A", "B", "C", "D", "E", "F", "G"]?.forEach((row: string) => {
                    worksheet.getCell(`${row}${lastHeadRow}`).fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "c9d9ff" },
                    };
                    worksheet.getCell(`${row}${lastHeadRow}`).font = {
                        color: { argb: "00000000" },
                        bold: true,
                    };
                    worksheet.getCell(`${row}${lastHeadRow}`).border = {
                        top: { style: "thin", color: { argb: "8F8F8F8F" } },
                        left: { style: "thin", color: { argb: "8F8F8F8F" } },
                        bottom: { style: "thin", color: { argb: "8F8F8F8F" } },
                        right: { style: "thin", color: { argb: "8F8F8F8F" } },
                    };
                    worksheet.getColumn(`${row}`).width = 35;
                });
                tableData?.forEach((item: any, index: number) => {
                    worksheet.addRow([
                        ...[
                            "Delay Event",
                            "Delay Start Date",
                            "Delay End Date",
                            "Submission Status under 28 Days",
                            "Submission Status (Otherwise)",
                            "Other Notices/Supporting Particulars",
                            "Under Clauses",
                        ]?.map((key) => item[key]?.toString()),
                    ]);
                    lastHeadRow += 1;
                });
            }
            if (tabSelect === "clauses_result") {
                headers = ["Sr No.", ...Object?.keys(tableData[0])];
                worksheet.columns = headers?.map((key) => ({ header: key, key, width: 10 }));
                ["A", "B", "C", "D"]?.forEach((row: string) => {
                    worksheet.getCell(`${row}${lastHeadRow}`).fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "c9d9ff" },
                    };
                    worksheet.getCell(`${row}${lastHeadRow}`).font = {
                        color: { argb: "00000000" },
                        bold: true,
                    };
                    worksheet.getCell(`${row}${lastHeadRow}`).border = {
                        top: { style: "thin", color: { argb: "8F8F8F8F" } },
                        left: { style: "thin", color: { argb: "8F8F8F8F" } },
                        bottom: { style: "thin", color: { argb: "8F8F8F8F" } },
                        right: { style: "thin", color: { argb: "8F8F8F8F" } },
                    };
                    worksheet.getColumn(`${row}`).width = 35;
                });
                tableData?.forEach((item: any, index: number) => {
                    worksheet.addRow([`${index + 1}`, ...[...Object?.keys(item)]?.map((key) => item[key]?.toString())]);
                    lastHeadRow += 1;
                });
            }

            worksheet.eachRow((row) =>
                row.eachCell(
                    (cell) =>
                        (cell.alignment = {
                            horizontal: "center",
                        })
                )
            );

            worksheet.views = [{ state: "frozen", xSplit: 0, ySplit: 0, activeCell: "A1", showGridLines: true }];
            workbook.xlsx.writeBuffer().then((data) => {
                let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                SaveAs(blob, `SoftsensorX Claims ${tabSelect} Report`);
            });
        }
    };

    return (
        <Box ref={tableRef} sx={{ display: "flex", justifyContent: "center", p: 2, background: "#FEFDFD", flex: 1 }}>
            <Box
                sx={{
                    width: "78vw",
                    background: "#fff",
                    border: "1px solid #E0E0E0",
                    borderRadius: "7px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    pt: 2,
                }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", px: 2 }}>
                    <Typography sx={{ color: "#007BFF" }}>Information Table</Typography>
                    <Stack direction={"row"} gap={1}>
                        <IconButton sx={{ border: "1px solid #f7f7f7" }} onClick={handleDownload}>
                            <Download sx={{ fontSize: "1.2rem", color: "#007BFF" }} />
                        </IconButton>
                        <IconButton
                            sx={{ border: "1px solid #f7f7f7" }}
                            onClick={() => navigate(contextStore?.claims?.state?.prevRoute || "/claims")}>
                            <Close sx={{ fontSize: "1.2rem", color: "#007BFF" }} />
                        </IconButton>
                    </Stack>
                </Box>
                <Box sx={{ px: 2, borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={tabSelect}
                        onChange={(e: any, value: "chronology" | "clauses_result" | "de_period_result") => {
                            setTableData([]);
                            setFilterStatus(false);
                            setTabSelect(value);
                        }}>
                        <Tab
                            sx={{ minWidth: "33%", p: 0.3, textTransform: "capitalize" }}
                            label={"Chronology (as per the Contract)"}
                            value={"chronology"}
                        />
                        <Tab
                            sx={{ minWidth: "33%", p: 0.3, textTransform: "capitalize" }}
                            label={"Delay Period Claimed"}
                            value={"de_period_result"}
                        />
                        <Tab
                            sx={{ minWidth: "33%", p: 0.3, textTransform: "capitalize" }}
                            label={"Clauses Refered by Contractor"}
                            value={"clauses_result"}
                        />
                    </Tabs>
                </Box>
                <Filters
                    loader={loader}
                    setTotalPages={setTotalPages}
                    setLoader={setLoader}
                    setFilterStatus={setFilterStatus}
                    setTableData={setTableData}
                    tabSelect={tabSelect}
                    page={page}
                />
                {loader ? (
                    <Box sx={{ height: "20rem", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Bounce />
                    </Box>
                ) : (
                    <>
                        {filterStatus ? (
                            <>
                                {tabSelect === "chronology" && (
                                    <Box
                                        sx={{
                                            flex: "1 0 10vh",
                                            overflow: "scroll",
                                            "& td": { fontSize: "0.75rem !important" },
                                            "& th": { fontSize: "0.8rem !important" },
                                        }}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            verticalAlign: "middle",
                                                            cursor: "pointer",
                                                            fontWeight: 600,
                                                        }}
                                                        size="small"
                                                        rowSpan={2}
                                                        onClick={() => setExpand((prev: boolean) => !prev)}>
                                                        {expand ? <Expand /> : <Collapse />}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "3rem",
                                                            fontWeight: 600,
                                                        }}
                                                        size="small"
                                                        rowSpan={2}>
                                                        Sn.
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "8rem",
                                                            fontWeight: 600,
                                                        }}
                                                        size="small"
                                                        colSpan={2}>
                                                        Delay Event
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "8rem",
                                                            fontWeight: 600,
                                                        }}
                                                        size="small"
                                                        rowSpan={2}>
                                                        Party
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "8rem",
                                                            fontWeight: 600,
                                                        }}
                                                        size="small"
                                                        rowSpan={2}>
                                                        Department
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "8rem",
                                                            fontWeight: 600,
                                                        }}
                                                        size="small"
                                                        rowSpan={2}>
                                                        Previous Reference Letter
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "8rem",
                                                            fontWeight: 600,
                                                        }}
                                                        size="small"
                                                        rowSpan={2}>
                                                        Letter Reference No.
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "8rem",
                                                            fontWeight: 600,
                                                        }}
                                                        size="small"
                                                        rowSpan={2}>
                                                        Date
                                                    </TableCell>
                                                    {["Response Letter(s)", "Response Letter's Date", "Response Time"]?.map((item) => (
                                                        <TableCell
                                                            sx={{
                                                                textAlign: "center",
                                                                border: "1px solid",
                                                                background: "#c9d9ff",
                                                                px: 0.3,
                                                                fontSize: "0.8rem",
                                                                minWidth: "8rem",
                                                                fontWeight: 600,
                                                            }}
                                                            size="small"
                                                            rowSpan={2}>
                                                            {item}
                                                        </TableCell>
                                                    ))}
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "8rem",
                                                            fontWeight: 600,
                                                        }}
                                                        size="small"
                                                        rowSpan={2}>
                                                        Referred In Claims
                                                    </TableCell>

                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "5rem",
                                                            fontWeight: 600,
                                                        }}
                                                        size="small"
                                                        rowSpan={2}>
                                                        Missing in Claims
                                                    </TableCell>
                                                    {["Letter Type"]?.map((item) => (
                                                        <TableCell
                                                            sx={{
                                                                textAlign: "center",
                                                                border: "1px solid",
                                                                background: "#c9d9ff",
                                                                px: 0.3,
                                                                fontSize: "0.8rem",
                                                                minWidth: "8rem",
                                                                fontWeight: 600,
                                                            }}
                                                            size="small"
                                                            rowSpan={2}>
                                                            {item}
                                                        </TableCell>
                                                    ))}

                                                    {/* <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "5rem",
                                                        }}
                                                        size="small"
                                                        rowSpan={2}>
                                                        Reason for letter
                                                    </TableCell> */}
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "17rem",
                                                            fontWeight: 600,
                                                        }}
                                                        size="small"
                                                        rowSpan={2}>
                                                        Gist
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid #0E0E0E",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "5rem",
                                                            fontWeight: 600,
                                                            color: "red",
                                                        }}
                                                        size="small"
                                                        colSpan={1}>
                                                        Contradictions wrt the (Contract / FIDIC (Yellow / Pink Book)
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid #0E0E0E",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "5rem",
                                                            fontWeight: 600,
                                                            color: "red",
                                                        }}
                                                        size="small"
                                                        colSpan={2}>
                                                        Contradictions wrt the Party's Letter
                                                    </TableCell>
                                                    {/* <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "10rem",
                                                        }}
                                                        size="small"
                                                        rowSpan={2}>
                                                        Rule Books
                                                    </TableCell> */}
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            top: "30px",
                                                            fontWeight: 600,
                                                        }}
                                                        size="small">
                                                        DE No.
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            top: "30px",
                                                            fontWeight: 600,
                                                        }}
                                                        size="small">
                                                        Sub Delay Event
                                                    </TableCell>
                                                    {/* <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid #0E0E0E",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            top: "30px",
                                                            fontWeight: 600,
                                                            color: "red",
                                                            minWidth: "8rem",
                                                        }}
                                                        size="small">
                                                        Contrasts
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            top: "30px",
                                                            fontWeight: 600,
                                                            minWidth: "16rem",
                                                        }}
                                                        size="small">
                                                        Clauses/Sub-Clauses in letter
                                                    </TableCell> */}
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            top: "30px",
                                                            fontSize: "0.8rem",
                                                            fontWeight: 600,
                                                        }}
                                                        size="small">
                                                        Relevance
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid #0E0E0E",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            top: "30px",
                                                            fontSize: "0.8rem",
                                                            fontWeight: 600,
                                                            color: "red",
                                                            minWidth: "16rem",
                                                        }}
                                                        size="small">
                                                        Reference Letter
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid #0E0E0E",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            top: "30px",
                                                            fontSize: "0.8rem",
                                                            fontWeight: 600,
                                                            color: "red",
                                                            minWidth: "16rem",
                                                        }}
                                                        size="small">
                                                        Letter Contrasts (Party Communication)
                                                    </TableCell>
                                                    {/* <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid #0E0E0E",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            top: "30px",
                                                            fontSize: "0.8rem",
                                                            fontWeight: 600,
                                                            color: "red",
                                                            minWidth: "16rem",
                                                        }}
                                                        size="small">
                                                        Clauses/Sub-Clauses in letter
                                                    </TableCell> */}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tableData?.map((row: any, index: number) => (
                                                    <Chronology index={index} row={row} expand={expand} tableRef={tableRef} />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                )}
                                {tabSelect === "de_period_result" && (
                                    <Box sx={{ flex: "1 0 10vh", overflow: "scroll" }}>
                                        <Table stickyHeader sx={{ mt: 2 }} size="small">
                                            <TableHead>
                                                <TableRow>
                                                    {[
                                                        "Delay Event",
                                                        "Delay Start Date",
                                                        "Delay End Date",
                                                        "Submission Status under 28 Days",
                                                        "Submission Status (Otherwise)",
                                                        "Other Notices/Supporting Particulars",
                                                        "Under Clauses",
                                                    ]?.map((head: string) => (
                                                        <TableCell
                                                            sx={{
                                                                textAlign: "center",
                                                                border: "1px solid",
                                                                background: "#c9d9ff",
                                                                p: 0.3,
                                                                minWidth: "6rem",
                                                            }}>
                                                            {head}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tableData?.map((row: any, index: number) => (
                                                    <TableRow>
                                                        {[
                                                            "Delay Event",
                                                            "Delay Start Date",
                                                            "Delay End Date",
                                                            "Submission Status under 28 Days",
                                                            "Submission Status (Otherwise)",
                                                            "Other Notices/Supporting Particulars",
                                                            "Under Clauses",
                                                        ]?.map((item: string) => (
                                                            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                                                                {row[item]}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                )}
                                {tabSelect === "clauses_result" && (
                                    <Box sx={{ flex: "1 0 10vh", overflow: "scroll" }}>
                                        <Table stickyHeader sx={{ mt: 2 }} size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            p: 0.3,
                                                            minWidth: "4rem",
                                                        }}>
                                                        Sn No.
                                                    </TableCell>
                                                    {Object?.keys(tableData[0])?.map((head: string) => (
                                                        <TableCell
                                                            sx={{
                                                                textAlign: "center",
                                                                border: "1px solid",
                                                                background: "#c9d9ff",
                                                                p: 0.3,
                                                                minWidth: "4rem",
                                                            }}>
                                                            {head}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tableData?.map((row: any, index: number) => (
                                                    <TableRow>
                                                        <TableCell
                                                            sx={{
                                                                border: "1px solid #D9D9D9",
                                                                p: 0.5,
                                                            }}>
                                                            {index + 1}
                                                        </TableCell>
                                                        {Object?.keys(row)?.map((item: string) => (
                                                            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                                                                {row[item]?.toString()}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                )}
                            </>
                        ) : (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "10rem",
                                }}>
                                <Typography sx={{ fontSize: "1.08rem", fontWeight: 600 }}>Please Filter The Conditions For Data</Typography>
                            </Box>
                        )}
                    </>
                )}
                {tabSelect === "chronology" && !loader && filterStatus && (
                    <Stack alignItems={"center"} justifyContent={"center"} py={1}>
                        <Pagination count={totalPages} page={page} color="primary" onChange={(_, value) => setPage(value)} />
                    </Stack>
                )}
            </Box>
        </Box>
    );
};

export default Tables;
