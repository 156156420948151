import { TableHead, TableRow, TableCell, TableBody, Table, Box } from "@mui/material";
import React, { useEffect, useState } from "react";

const Relevencies = ({ value }: { value: any }) => {
    const [relevencie, setRelevencie] = useState<any>("");
    useEffect(() => {
        try {
            const json = JSON?.parse(value);
            setRelevencie(json);
        } catch (error) {
            setRelevencie(value);
        }
    }, [value]);

    if (Array?.isArray(relevencie)) {
        return (
            <Box sx={{ m: 1 }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {["Document", "Clause Statement"]?.map((head) => (
                                <TableCell sx={{ background: "#c9d9ff", border: "1px solid #D9D9D9" }}>{head}</TableCell>
                            ))}
                            <TableCell sx={{ background: "#c9d9ff", border: "1px solid #D9D9D9", fontWeight: 600, color: "red", minWidth: "8rem" }}>
                                Contrasts
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {relevencie?.map((row: any) => (
                            <TableRow>
                                <TableCell size="small" sx={{ border: "1px solid #D9D9D9", minWidth: "10rem" }}>
                                    {row["Document"]}
                                </TableCell>
                                <TableCell size="small" sx={{ border: "1px solid #D9D9D9", minWidth: "10rem" }}>
                                    {row["Clause"]}
                                </TableCell>
                                <TableCell size="small" sx={{ border: "1px solid #D9D9D9", minWidth: "10rem" }}>
                                    {row["Contrasts"]}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    }
    return <div>{relevencie}</div>;
};

export default Relevencies;
